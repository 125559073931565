import React from "react"
import styled from 'styled-components'

import Layout from "../components/layout"
import SEO from "../components/seo"
import SoumarinWrapper from '../images/wrapper@2x.png'
import SoumarinBox from '../images/soumarin-box@2x.png'

const Container = styled.div`
  margin: 20px auto 0;
  width: 100%;
`

const SoumarinWrapperImage = styled.div`
  background-image: url(${SoumarinWrapper});
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  width: 100%;
  border: 0 none;
  outline: none;
  padding: 24%;
}
`

const SoumarinBoxImage = styled.div`
  background-image: url(${SoumarinBox});
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  width: 100%;
  border: 0 none;
  outline: none;
  padding: 30%;
  margin-bottom: 20px;
}
`

const SubHeading = styled.h3`
  margin: 20px auto 0;
  text-align: center;
  font-size: 13px;
  font-family: 'Quattrocento Sans', sans-serif;
  color: #888;
`

const Heading = styled.h1`
  margin: 15px auto 15px;
  max-width: 70%;
  text-align: center;
  font-family: 'Quattrocento Sans', sans-serif;
  font-size: 28px;
  line-height: 31px;
  padding-bottom: 20px;    
  
  @media only screen and (max-width : 375px) {
    max-width: 90%;
  } 
`

const JoinHeading = styled(Heading)`
  margin-top: 30px;
  padding-top: 40px;
  border-top: 1px solid #e2e2e2;
  font-size: 32px;
`

const Text = styled.div`
  margin: 0 auto;
  max-width: 70%;
  font-family: 'Quattrocento Sans', sans-serif;
  font-size: 20px;
  line-height: 32px;
  text-align: justify;
    
  @media only screen and (max-width : 375px) {
    font-size: 16px;
    line-height: 26px;
    max-width: 90%;
  } 
`

const BenefitsText = styled(Text)`
  margin-top: 20px;
  
  li {
    margin-bottom: 3px;
  }
`

const Description = styled(Text)`
  margin-top: 10px;
  padding: 20px 40px;
  border-top: 1px solid #e2e2e2;
  border-bottom: 1px solid #e2e2e2;
  
  @media only screen and (max-width : 375px) {
    padding: 20px 20px;
    text-align: justify;
  } 
`

const RetailersPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`soumarin`, `chocolate`, 'san francisco', 'retailers']} />
    <SubHeading>
      RETAILER LAUNCH ANNOUNCEMENT
    </SubHeading>
    <Heading>
      Launching this Fall!
    </Heading>
    <Container>
      <SoumarinWrapperImage />
    </Container>
    <Description>
      Soumarin hot chocolate submarines make the perfect hot chocolate! Simply heat up your favorite milk, drop in a submarine and stir. Made with
      couverture dark chocolate for a rich and delicious hot chocolate.
    </Description>
    <Container>
      <SoumarinBoxImage />
    </Container>
    <JoinHeading>
      Join our launch day
    </JoinHeading>
    <Text>
      <strong>Pre-order</strong> a case for your shelves today to join our launch! Your credit card won't be charged until <strong>15 days after delivery!</strong>
    </Text>
    <br />
    <Text>
      <strong>MSRP:</strong> $1.49 / submarine
      <br />
      <strong>Wholesale:</strong> $0.99 / submarine
      <br />
    </Text>
    <BenefitsText>
      <strong>Launch benefits:</strong>
      <ul>
        <li>Preferred pricing</li>
        <li>Net 15 billing after delivery</li>
        <li>Listing on our launch day retailers map</li>
      </ul>
    </BenefitsText>
  </Layout>
)

export default RetailersPage
